import styled from "styled-components"
import breakpoints from "../../../../../configs/breakpoints"

export const Container = styled.article`
  display: contents;

  .margin {
    grid-column: 2/-2;
    margin-top: var(--gap-27);
    margin-bottom: var(--gap-50);
    border-bottom: 1px solid var(--clr-green);
  }

  & > a {
    display: none;
    visibility: hidden;
  }

  @media screen and (max-width: ${breakpoints.tablet}) {
    .margin {
      grid-column: 1/-1;
      margin-top: var(--gap-27);
      margin-bottom: var(--gap-50);
    }
  }

  @media screen and (max-width: ${breakpoints.mobile}) {
    a {
      display: flex;
      visibility: visible;
      grid-column: 1/-1;
      justify-self: center;
      margin-top: var(--gap-50);
    }
  }
`

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  grid-column: 2/8;

  a {
    margin-top: var(--gap-50);
  }

  h3 {
    font-size: 1.45em;
    margin-top: var(--gap-27);
    margin-bottom: var(--gap-18);
  }

  @media screen and (max-width: ${breakpoints.tablet}) {
    grid-column: span 5;

    .date,
    h3 {
      font-size: 1.45em !important;
      font-weight: bold;
    }
  }

  @media screen and (max-width: ${breakpoints.mobile}) {
    grid-column: 1 / -1;

    & > a {
      display: none;
    }
  }
`

export const ImgWrapper = styled.div`
  grid-column: span 4;
  display: inline-flex;
  justify-content: flex-end;

  div {
    display: inline;
    height: 100%;
    width: 100%;
    max-height: 335px;
    max-width: 280px;
  }
  img {
    height: 100%;
  }

  @media screen and (max-width: ${breakpoints.tablet}) {
    grid-column: span 3;
  }

  @media screen and (max-width: ${breakpoints.mobile}) {
    grid-column: 1 / -1;
    display: block;
    height: 100%;
    max-height: 300px;
    margin-top: var(--gap-27);

    div {
      display: block;
      max-width: unset;
      max-height: 300px;

      height: unset;
      width: unset;
    }

    img {
      height: 100%;
      width: auto;
      max-height: 300px;
      margin: auto;
    }
  }
`
