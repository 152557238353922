import styled from "styled-components"

export const Container = styled.section`
  display: contents;

  & > h3 {
    grid-column: 1/-1;
    margin-top: var(--gap-165);
    margin-bottom: var(--gap-50);
  }

  & article:last-child {
    .margin {
      margin-bottom: 0;
    }
  }
`
