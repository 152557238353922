import styled from "styled-components"
import breakpoints from "../../../../configs/breakpoints"

export const Container = styled.section`
  display: contents;

  b {
    font-weight: bold;
  }

  h3,
  a {
    grid-column: 1/-1;
  }

  a {
    justify-self: center;
  }

  h3 {
    margin-top: var(--gap-165);
    margin-bottom: var(--gap-32);
  }

  @media screen and (max-width: ${breakpoints.tablet}) {
    h3 {
      margin-top: var(--gap-83);
    }
  }
`

export const Ul = styled.ul`
  display: contents;

  li {
    display: flex;
    align-items: center;
    grid-column: span 4;
    height: 130px;
    border: 1px solid var(--clr-green);
    padding-right: var(--gap-18);
    margin-bottom: var(--gap-50);

    .img {
      margin-right: var(--gap-13);
      height: 100%;
      width: 100%;
      min-width: 110px;
      max-width: 110px;

      img {
        max-height: 128px;
        max-width: 90px;
      }
    }

    .temp {
      background: var(--clr-green);
      min-width: 90px !important;
      max-width: 90px !important;
      margin-right: 35px;
    }
  }

  @media screen and (max-width: ${breakpoints[1200]}) {
    li {
      grid-column: span 6;
    }
  }

  @media screen and (max-width: ${breakpoints.tablet}) {
    li {
      grid-column: 1 / -1;
      margin-bottom: var(--gap-18);
    }
  }

  @media screen and (max-width: ${breakpoints.mobile}) {
    li {
      margin-bottom: var(--gap-13);
    }
  }

  @media screen and (max-width: 394px) {
    li {
      height: 160px;

      .img {
        margin-right: var(--gap-13);
        height: 100%;
        width: 100%;
        min-width: 110px;
        max-width: 110px;

        img {
          max-height: 158px;
          max-width: 120px;
        }
      }

      p span:nth-child(odd):after {
        content: "-";
      }
    }
  }
`
