import React from "react"
import Intro from "./components/intro"

import Leistungen from "./components/leistungen"
import Ueber from "./components/ueber"
import Kooperationspartner from "./components/kooperationspartner"
import Aktuelles from "./components/aktuelles"
import Artikel from "./components/artikel/Artikel"
import Empfehlung from "./components/empfehlung"
import Books from "@modules/startseite/components/books/Books"

const Startseite = () => {
  return (
    <div style={{ display: "contents" }}>
      <Intro />
      <Books />
      <Leistungen />
      <Ueber />
      <Kooperationspartner />
      <Aktuelles />
      {typeof window !== "undefined" && <Artikel />}
      {/* 
      
      <section>
        <h3>Einige Unserer Kunden</h3>
        <div className="img"></div>
      </section>
      */}
      <Empfehlung />
    </div>
  )
}

export default Startseite
