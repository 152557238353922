import styled from "styled-components"
import breakpoints from "../../../../configs/breakpoints"

export const SliderWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: var(--gap-50);
  height: 100%;
  width: 100%;
  max-height: 375px;
  overflow: hidden;
`

export const SlideContainer = styled.div<{ slider: number }>`
  display: flex;
  height: 100%;
  width: calc(100% - (var(--svg-width) * 2));
  transform: translateX(calc(-100% * ${props => props.slider}));
  transition: transform 250ms ease-in-out;
  flex-grow: 1;
`

export const ItemContent = styled.div`
  display: flex;
  flex-direction: column;
  border: 1px solid var(--clr-green);
  padding: var(--gap-32);
  height: 320px;
  box-shadow: 5px 5px 16px 0px rgba(0, 0, 0, 0.1);

  .title {
    text-transform: uppercase;
    font-weight: bold;
  }

  .text {
    margin-top: var(--gap-27);
    margin-bottom: var(--gap-43);
  }

  a {
    margin: 0 auto;
    margin-top: auto;
  }
`

export const CircleContainer = styled.ul`
  display: flex;
  justify-content: center;

  li {
    margin-right: var(--gap-13);
    cursor: pointer;

    &:last-child {
      margin-right: 0;
    }
  }
`

export const Circle = styled.div<{ active: boolean }>`
  background: var(--clr-green);
  border-radius: 5rem;
  width: 1rem;
  height: 1rem;

  ${props =>
    props.active
      ? "background:var(--clr-grey)"
      : "box-shadow: 5px 5px 16px 0px rgba(0, 0, 0, 0.1);"}
`

export const Item = styled.div`
  padding: var(--gap-18);
  flex: 0 0 calc(100% / var(--items-per-screen));
  max-width: calc(100% / var(--items-per-screen));
`

export const Section = styled.section`
  grid-column: 1/-1;
  margin-top: var(--gap-165);
  --items-per-screen: 3;
  --items-gap: var(--gap-32);
  --svg-width: 35px;

  /* * {
    border: 1px solid black;
  } */

  .svg-wrapper {
    background: white;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1;
    cursor: pointer;

    &:last-child {
      transform: rotate(180deg);
    }
  }

  svg {
    min-height: var(--svg-width);
    min-width: var(--svg-width);
    max-height: var(--svg-width);
    max-width: var(--svg-width);

    & > * {
      stroke: var(--clr-green);
    }
  }

  @media screen and (max-width: 1400px) {
    --items-per-screen: 2;
    --items-gap: var(--gap-18);
  }

  @media screen and (max-width: 1024px) {
    --items-per-screen: 1;
    --items-gap: 5px;
  }

  @media screen and (max-width: 450px) {
    position: relative;

    button.svg-wrapper {
      position: absolute;
      left: -25px;
      top: 0;
      bottom: 0;
      height: 100%;
      margin-top: var(--gap-50);
      background: unset;

      &:last-child {
        left: unset;

        right: -25px;
      }
    }
  }
`
