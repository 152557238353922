import React from "react"
import { slugs } from "@configs/slugs"
import Button from "@modules/common/button"
import { graphql, useStaticQuery } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

import * as Styled from "./Leistungen.styles"

const query = graphql`
  query {
    startseite_coaching: file(
      relativePath: { regex: "/startseite_coaching.jpg/" }
    ) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED, quality: 100, layout: CONSTRAINED)
        id
      }
    }
    startseite_outdoortraining: file(
      relativePath: { regex: "/startseite_outdoortraining.jpg/" }
    ) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED, quality: 100, layout: CONSTRAINED)
        id
      }
    }
    startseite_organisationsentwicklung: file(
      relativePath: { regex: "/startseite_organisationsentwicklung.jpg/" }
    ) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED, quality: 100, layout: CONSTRAINED)
        id
      }
    }
    startseite_seminare_und_workshops: file(
      relativePath: { regex: "/startseite_seminare_und_workshops.jpg/" }
    ) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED, quality: 100, layout: CONSTRAINED)
        id
      }
    }
  }
`

const Leistungen = () => {
  const {
    startseite_coaching,
    startseite_organisationsentwicklung,
    startseite_seminare_und_workshops,
    startseite_outdoortraining,
  } = useStaticQuery(query)

  const startseite_coaching_image: any = getImage(startseite_coaching)
  const startseite_outdoortraining_image: any = getImage(
    startseite_outdoortraining
  )
  const startseite_organisationsentwicklung_image: any = getImage(
    startseite_organisationsentwicklung
  )
  const startseite_seminare_und_workshops_image: any = getImage(
    startseite_seminare_und_workshops
  )

  return (
    <Styled.Container>
      <h3>Unsere Leistungen</h3>
      <Styled.Ul>
        <li>
          <div className="img">
            <GatsbyImage
              alt="test"
              image={startseite_organisationsentwicklung_image}
              objectFit="contain"
            />
          </div>
          <Styled.LiText>
            <h3>
              <span>Organisations</span>
              <span>entwicklung</span>
            </h3>
            <p>
              Wir wollen die Veränderungen und Bewegungen in Organisationen
              unterstützen durch das Finden und Umsetzen einer attraktiven
              Zukunftsperspektive.
            </p>
            <Button
              type="middle"
              href={slugs.leistungen.organisationsentwicklung}
            >
              mehr Details
            </Button>
          </Styled.LiText>
        </li>
        <li>
          <div className="img">
            <GatsbyImage
              alt="test"
              image={startseite_coaching_image}
              objectFit="contain"
            />
          </div>
          <Styled.LiText>
            <h3>Coaching</h3>
            <p>
              Berufliche Standortbestimmung, Karriereplanung oder der Umgang mit
              persönlichen und beruflichen Veränderungen: Richten Sie Ihren Kurs
              aus, wir unterstützen Sie gerne dabei!
            </p>
            <Button type="middle" href={slugs.leistungen.coaching}>
              mehr Details
            </Button>
          </Styled.LiText>
        </li>
        <li>
          <div className="img">
            <GatsbyImage
              alt="test"
              image={startseite_seminare_und_workshops_image}
              objectFit="contain"
            />
          </div>
          <Styled.LiText>
            <h3>Seminare & Workshops</h3>
            <p style={{marginTop: "-20px"}}>
            (z.B. Führungskräfteentwicklung)
            </p>
            <p>
              Bei uns finden Sie aktuelle Wissens- und Knowhow-Vermittlung in
              Seminaren und Workshops. Wir möchten Sie inspirieren - beruflich
              wie auch persönlich!
            </p>
            <Button
              type="middle"
              href={slugs.leistungen["seminare-und-workshops"]}
            >
              mehr Details
            </Button>
          </Styled.LiText>
        </li>
        <li>
          <div className="img">
            <GatsbyImage
              alt="test"
              image={startseite_outdoortraining_image}
              objectFit="contain"
            />
          </div>
          <Styled.LiText>
            <h3>Outdoor-Training</h3>
            <p>Lernen im hochwertigen Erlebnisraum.</p>
            <Button type="middle" href={slugs.leistungen["outdoor-training"]}>
              mehr Details
            </Button>
          </Styled.LiText>
        </li>
      </Styled.Ul>
    </Styled.Container>
  )
}

export default Leistungen
