import { graphql, useStaticQuery } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import React from "react"
import { slugs } from "../../../../configs/slugs"
import Button from "../../../common/button"

import * as Styled from "./Kooperationspartner.styles"

const query = graphql`
  query {
    anette_kuhr: file(relativePath: { regex: "/anette_kuhr.jpg$/" }) {
      childImageSharp {
        gatsbyImageData(
          layout: FULL_WIDTH
          placeholder: TRACED_SVG
          quality: 100
        )
      }
    }
    heiko_thurner: file(relativePath: { regex: "/heiko_thurner.jpg$/" }) {
      childImageSharp {
        gatsbyImageData(
          layout: FULL_WIDTH
          placeholder: TRACED_SVG
          quality: 100
        )
      }
    }
    ingrid_wild: file(relativePath: { regex: "/ingrid_wild.jpg$/" }) {
      childImageSharp {
        gatsbyImageData(
          layout: FULL_WIDTH
          placeholder: TRACED_SVG
          quality: 100
        )
      }
    }
    marianne_frye: file(relativePath: { regex: "/marianne_frye.jpg$/" }) {
      childImageSharp {
        gatsbyImageData(
          layout: FULL_WIDTH
          placeholder: TRACED_SVG
          quality: 100
        )
      }
    }
    achim_stark: file(relativePath: { regex: "/achim_stark.jpg$/" }) {
      childImageSharp {
        gatsbyImageData(
          layout: FULL_WIDTH
          placeholder: TRACED_SVG
          quality: 100
        )
      }
    }
    thomas_lehner: file(relativePath: { regex: "/thomas_lehner.jpg$/" }) {
      childImageSharp {
        gatsbyImageData(
          layout: FULL_WIDTH
          placeholder: TRACED_SVG
          quality: 100
        )
      }
    }
    kaja_novac: file(relativePath: { regex: "/kaja_novac.jpg$/" }) {
      childImageSharp {
        gatsbyImageData(
          layout: FULL_WIDTH
          placeholder: TRACED_SVG
          quality: 100
        )
      }
    }
    maria_tiefenthaler: file(
      relativePath: { regex: "/maria_tiefenthaler.jpg$/" }
    ) {
      childImageSharp {
        gatsbyImageData(
          layout: FULL_WIDTH
          placeholder: TRACED_SVG
          quality: 100
        )
      }
    }
  }
`

const Kooperationspartner = () => {
  const {
    anette_kuhr: anette_kuhr_image,
    heiko_thurner: heiko_thurner_image,
    ingrid_wild: ingrid_wild_image,
    marianne_frye: marianne_frye_image,
    achim_stark: achim_stark_image,
    thomas_lehner: thomas_lehner_image,
    kaja_novac: kaja_novac_image,
    maria_tiefenthaler: maria_tiefenthaler_image,
  } = useStaticQuery(query)

  const anette_kuhr: any = getImage(anette_kuhr_image)
  const heiko_thurner: any = getImage(heiko_thurner_image)
  const ingrid_wild: any = getImage(ingrid_wild_image)
  const marianne_frye: any = getImage(marianne_frye_image)
  const achim_stark: any = getImage(achim_stark_image)
  const thomas_lehner: any = getImage(thomas_lehner_image)
  const kaja_novac: any = getImage(kaja_novac_image)
  const maria_tiefenthaler: any = getImage(maria_tiefenthaler_image)

  return (
    <Styled.Container>
      <h3>Unsere Kooperationspartner</h3>
      <Styled.Ul>
        <li>
          <div className="img">
            <GatsbyImage alt="test" image={marianne_frye} objectFit="cover" />
          </div>
          <div>
            <p>
              <b>Marianne Frye</b>
            </p>
            <p>
              Systemische Paar- und <span>Familien</span>
              <span>therapeutin</span>, Systemische Supervisorin
            </p>
          </div>
        </li>
        <li>
          <div className="img">
            <GatsbyImage alt="test" image={anette_kuhr} objectFit="cover" />
          </div>
          <div>
            <p>
              <b>Annette Kuhr</b>
            </p>
            <p>Trainerin, Beraterin, Coach</p>
          </div>
        </li>
        <li>
          <div className="img">
            <GatsbyImage alt="test" image={thomas_lehner} objectFit="cover" />
          </div>
          <div>
            <p>
              <b>Thomas Lehner</b>
            </p>
            <p>Consultant, Coach, Dipl.-Ing. Schwerpunkt Abfallwirtschaft</p>
          </div>
        </li>
        <li>
          <div className="img">
            <GatsbyImage alt="test" image={kaja_novac} objectFit="cover" />
          </div>
          <div>
            <p>
              <b>Kaja Novak</b>
            </p>
            <p>
              <span>Sprach</span>
              <span>wissenschaftlerin</span>, <span>Organisations</span>
              <span>entwicklerin</span>, Coach, <span>trauma</span>
              <span>oriented</span> Facilitator
            </p>
          </div>
        </li>
        <li>
          <div className="img">
            <GatsbyImage alt="test" image={achim_stark} objectFit="cover" />
          </div>
          <div>
            <p>
              <b>Achim Stark</b>
            </p>
            <p>Trainer und Coach</p>
          </div>
        </li>
        <li>
          <div className="img">
            <GatsbyImage alt="test" image={heiko_thurner} objectFit="cover" />
          </div>
          <div>
            <p>
              <b>Heiko Thurner</b>
            </p>
            <p>Freiberuflicher Trainer seit 2001</p>
          </div>
        </li>
        <li>
          <div className="img">
            <GatsbyImage alt="test" image={ingrid_wild} objectFit="cover" />
          </div>
          <div>
            <p>
              <b>Ingrid Wild</b>
            </p>
            <p>selbständig mit Wild-Pferd: Ausbildung für Pferd & Reiter</p>
          </div>
        </li>
        <li>
          <div className="img">
            <GatsbyImage
              alt="test"
              image={maria_tiefenthaler}
              objectFit="cover"
            />
          </div>
          <div>
            <p>
              <b>Maria Tiefenthaler</b>
            </p>
            <p>Beraterin und Coach</p>
          </div>
        </li>
      </Styled.Ul>
      <Button href={slugs["ueber-uns-und-unser-netzwerk"]} type="middle">
        mehr erfahren
      </Button>
    </Styled.Container>
  )
}

export default Kooperationspartner
