import React, { useEffect, useState } from "react"
import Button from "../../../common/button"

// styles
import * as Styled from "./Artikel.styles"

// assets
import Arrow from "@assets/slide/arrow.svg"
import createKey from "@services/key"

// pdfs
import fuehrungskompetenz_fuer_ingenieure from "../../../../assets/pdfs/fuehrungskompetenz_fuer_ingenieure.pdf"
import eine_unterstuetzung_beim_umgang_mit_veraenderung from "../../../../assets/pdfs/eine_unterstuetzung_beim_umgang_mit_veraenderung.pdf"
import die_intelligente_schlagfertigkeit from "../../../../assets/pdfs/die_intelligente_schlagfertigkeit.pdf"
import konfiliktmanagement from "../../../../assets/pdfs/konfiliktmanagement.pdf"
import wertschoepfung_durch_wertschaetzung from "../../../../assets/pdfs/wertschoepfung_durch_wertschaetzung.pdf"
import so_geht_das_grosshirn_wieder_online from "../../../../assets/pdfs/so_geht_das_grosshirn_wieder_online.pdf"
import eworker_lehner from "../../../../assets/pdfs/eworker_lehner.pdf"

const Artikel = () => {
  const [slider, setSlider] = useState(0)
  const [width, setWidth] = useState(window?.innerWidth)

  useEffect(() => {
    const resize = () => {
      setWidth(window?.innerWidth)
      setSlider(0)
    }
    window?.addEventListener("resize", resize)
    return () => window?.removeEventListener("resize", resize)
  })

  const data = [
    {
      title: "Resilienz und mentale Stärke",
      text: "FiNet Academy - Gastbeitrag (2024) Christine Lehner",
      href: eworker_lehner,
    },
    {
      title: "So geht das Großhirn wieder online",
      text: "Amberger Zeitung (Feb. 2020) Christine Lehner / Sabine Weihe",
      href: so_geht_das_grosshirn_wieder_online,
    },
    {
      title: "Wertschöpfung durch Wertschätzung",
      text: "Perspektive Mittelstand (Okt. 2010) Christine Lehner / Sabine Weihe",
      href: wertschoepfung_durch_wertschaetzung,
    },
    {
      title: "Konfliktmanagement - Wie man Nervensägen stumpf macht",
      text: "Perspektive Mittelstand (23. Jun. 2010) Christine Lehner / Sabine Weihe",
      href: konfiliktmanagement,
    },
    {
      title: "Die intelligente Schlagfertigkeit",
      text: "KMU-Magazin (Apr. 08) Christine Lehner / Sabine Weihe",
      href: die_intelligente_schlagfertigkeit,
    },
    {
      title: "Eine Unterstützung beim Umgang mit Veränderungen",
      text: "Mittelbayerische Zeitung (6./7. Jan. 2007) Christine Lehner",
      href: eine_unterstuetzung_beim_umgang_mit_veraenderung,
    },
    {
      title: "Führungskompetenz für Ingenieure",
      text: "Programm der Fachhochschule Rosenheim, Seeoner Kreis – Forum für Wissenschaft und Wirtschaft der FH Rosenheim / Sabine Weihe",
      href: fuehrungskompetenz_fuer_ingenieure,
    },
  ]

  const count = () => {
    if (width >= 1400) {
      return 3
    } else if (width >= 1024) {
      return 2
    } else {
      return 1
    }
  }

  const rightClick = () => {
    if (slider + 1 < Math.ceil(data.length / count())) {
      setSlider(slider + 1)
    } else {
      setSlider(0)
    }
  }

  const leftClick = () => {
    if (slider - 1 >= 0) {
      setSlider(slider - 1)
    } else {
      setSlider(Math.ceil(data.length / count()) - 1)
    }
  }

  const createCircles = () => {
    const circlesCount = Math.ceil(data.length / count())
    return Array.from(Array(circlesCount).keys()).map(i => (
      <li key={createKey()}>
        <Styled.Circle
          active={slider !== i}
          key={createKey()}
          onClick={() => setSlider(i)}
        />
      </li>
    ))
  }

  return (
    <Styled.Section>
      <h3>Presseartikel</h3>
      <Styled.SliderWrapper>
        <button className="svg-wrapper" onClick={leftClick}>
          <Arrow />
        </button>
        <Styled.SlideContainer className="slider" slider={slider}>
          {data.map(obj => (
            <Styled.Item key={createKey()}>
              <Styled.ItemContent>
                <p className="title" key={createKey()}>
                  {obj.title}
                </p>
                <p className="text" key={createKey()}>
                  {obj.text}
                </p>
                {
                  // TODO links ergaenzen
                }
                <Button
                  link={false}
                  href={obj.href}
                  type="middle"
                  key={createKey()}
                >
                  zum Artikel
                </Button>
              </Styled.ItemContent>
            </Styled.Item>
          ))}
        </Styled.SlideContainer>
        <button className="svg-wrapper" onClick={rightClick}>
          <Arrow />
        </button>
      </Styled.SliderWrapper>
      <Styled.CircleContainer>{createCircles()}</Styled.CircleContainer>
    </Styled.Section>
  )
}

export default Artikel
