import styled from "styled-components"
import breakpoints from "../../../../configs/breakpoints"

export const Container = styled.section`
  display: contents;

  h3 {
    margin-top: var(--gap-165);
    margin-bottom: var(--gap-45);
    grid-column: 1/-1;
  }

  .content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    grid-column: 7 / -1;
    max-width: 650px;
  }

  .img {
    grid-column: span 5;
    display: flex;

    div {
      margin: auto;
      width: 100%;
      height: auto;
    }

    img {
      margin-top: auto;
    }
  }

  .title {
    font-size: 2em;
    font-weight: bold;
    margin-bottom: var(--gap-50);
  }

  .subtitle {
    font-size: 1.45em;
    font-weight: bold;
    margin-bottom: var(--gap-32);
  }

  .text {
    margin-bottom: var(--gap-27);
  }

  @media screen and (max-width: ${breakpoints.tablet}) {
    .content {
      grid-column: 1 / -1;
    }

    .img {
      grid-column: 1 / -1;
    }

    .title {
      margin-top: var(--gap-45);
      margin-bottom: var(--gap-32);
    }
  }
`
