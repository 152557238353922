import React from "react"
import { slugs } from "@configs/slugs"
import Button from "@modules/common/button"
import { graphql, useStaticQuery } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

import * as Styled from "./Ueber.styles"

const query = graphql`
  query {
    christine_sabine: file(relativePath: { regex: "/christine_sabine/" }) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED, quality: 100, layout: CONSTRAINED)
        id
      }
    }
  }
`

const Ueber = () => {
  const { christine_sabine } = useStaticQuery(query)

  const image: any = getImage(christine_sabine)

  return (
    <Styled.Container>
      <h3>Über uns</h3>
      <Styled.Intro>
        Wir arbeiten seit über 20 Jahren im Bereich Persönlichkeits- und
        Organisationsentwicklung. Unser Ziel ist es, die Denk- und
        Handlungsmuster von Menschen und Organisationen bewusst zu machen und
        gemeinsam zu reflektieren.
      </Styled.Intro>
      <Styled.ImgContainer>
        <GatsbyImage alt="test" image={image} />
      </Styled.ImgContainer>
      <Styled.Text>
        <Styled.Quote>
          <blockquote>
            "Wenn wir die Herausforderungen des 21. Jahrhunderts bestehen
            wollen, müssen wir vor allem eines ändern: unsere Art zu denken."
          </blockquote>
          <p>- Einstein</p>
        </Styled.Quote>
        <Styled.ProfilContainer>
          <Styled.Profil>
            <p>Christine Lehner</p>
            <p>
              Ich bin Dipl.-Päd. und Psychotherapeutin und freiberufliche
              Beraterin und Trainerin mit den Schwerpunkten mentale Stärke und
              Stressmanagement.
            </p>
          </Styled.Profil>
          <Styled.Profil>
            <p>Sabine Weihe</p>
            <p>
              Ich bin Dipl.-Verwaltungswissenschaftlerin, freiberuflich und
              angestellt tätig als Beraterin, Trainerin und Coach, mit den
              Schwerpunkten Organisationsentwicklung und Kommunikation.
            </p>
          </Styled.Profil>
        </Styled.ProfilContainer>
      </Styled.Text>
      <Button href={slugs["ueber-uns-und-unser-netzwerk"]} type="middle">
        zu den Profilen
      </Button>
    </Styled.Container>
  )
}

export default Ueber
