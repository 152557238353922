import styled from "styled-components"
import breakpoints from "../../../../configs/breakpoints"

export const Content = styled.div`
  margin-right: var(--gap-107);

  h1 {
    margin-bottom: var(--gap-50);
  }

  p {
    margin-bottom: var(--gap-27);
  }
`

export const Container = styled.section`
  display: flex;
  grid-column: 1 / -1;
  align-items: center;
  justify-content: center;
  margin-top: -50px;

  .img {
    min-width: 400px;
    max-width: 700px;
    width: 100vw;
    height: 100%;

    .mobile {
      display: none;
    }
  }

  @media screen and (max-width: ${breakpoints[1200]}) {
    flex-direction: column;

    .img {
      order: 0;
      margin-bottom: var(--gap-66);
      min-width: 250px;
      max-width: 500px;
    }

    ${Content} {
      order: 1;
      margin-right: 0;
    }
  }

  @media screen and (max-width: ${breakpoints.tablet}) {
    .img {
      max-width: 500px;
    }
  }
`
