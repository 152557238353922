import React from "react"

// components
import Layout from "@modules/layout"
import Seo from "@modules/seo"
import Startseite from "@modules/startseite"

const IndexPage = ({ location }: any) => (
  <Layout location={location}>
    <Seo title="Startseite">
      <Startseite />
    </Seo>
  </Layout>
)

export default IndexPage
