import { Link } from "gatsby"
import styled, { css } from "styled-components"
import breakpoints from "@configs/breakpoints"
import { LinkType } from "./Button"

const BaseLink = css`
  display: flex;
  background: var(--clr-green);
  font-weight: bold;
  -webkit-box-shadow: 5px 5px 8px 0px rgba(0, 0, 0, 0.1);
  box-shadow: 5px 5px 8px 0px rgba(0, 0, 0, 0.1);
  text-align: center;
  justify-content: center;
  align-items: center;
  word-break: break-all;

  &:hover,
  &:focus {
    background: var(--clr-green-hover);
  }

  &:active {
    background: var(--clr-green-clicked);
  }
`

const Small = css`
  ${BaseLink}
  width: 100%;
  max-width: 229px;
  height: 60px;
  min-height: 60px;

  @media screen and (max-width: ${breakpoints.tablet}) {
    width: 100%;
    max-width: 163px;
    height: 43px;
    min-height: 43px;
  }
`

const Middle = css`
  ${BaseLink}
  width: 100%;
  max-width: 302px;
  height: 60px;
  min-height: 60px;

  @media screen and (max-width: ${breakpoints.tablet}) {
    width: 100%;
    max-width: 275px;
    height: 42px;
    min-height: 42px;
  }

  @media screen and (max-width: ${breakpoints.mobile}) {
    width: 100%;
    height: 49px;
    min-height: 49px;
  }
`

const Big = css`
  ${BaseLink}
  width: 100%;
  max-width: 339px;
  height: 60px;
  min-height: 60px;

  @media screen and (max-width: ${breakpoints.tablet}) {
    width: 100%;
    max-width: 300px;
    height: 42px;
    min-height: 42px;
  }

  @media screen and (max-width: ${breakpoints.mobile}) {
    width: 100%;
    height: 50px;
    min-height: 50px;
  }
`

const ChooseType = (type: LinkType) => {
  switch (type) {
    case "small":
      return Small
    case "middle":
      return Middle
    case "big":
      return Big
  }
}

export const SLink = styled(Link)<{
  type: LinkType
}>`
  ${props => ChooseType(props.type)}
`

export const A = styled.a<{
  type: LinkType
}>`
  ${props => ChooseType(props.type)}
`
