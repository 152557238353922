import styled from "styled-components"
import breakpoints from "../../../../configs/breakpoints"

export const Container = styled.section`
  display: flex;
  grid-column: 1 / -1;
  justify-content: center;
  margin-top: var(--gap-165);

  .content {
    padding: 50px 20px;

    display: flex;
    justify-content: center;
    background: var(--clr-grey);
    max-width: 600px;
  }

  h3 {
    margin-bottom: 20px;
    grid-column: 1/-1;
    font-size: 1.2em;
  }

  .img {
    display: flex;
    width: 100vw;
    max-width: 250px;
    margin-right: 25px;

    div {
      margin: auto;
      width: 100%;
      height: auto;
    }

    img {
      margin-top: auto;
    }
  }

  .title {
    font-size: 1em;
    font-weight: bold;
    margin-bottom: 15px;
  }

  .subtitle {
    font-size: 1.45em;
    font-weight: bold;
    margin-bottom: var(--gap-32);
  }

  .text {
    margin-bottom: var(--gap-27);
    font-size: 0.8em;
  }

  @media screen and (max-width: ${breakpoints.tablet}) {
    .content {
      flex-direction: column;
    }

    .img {
      margin: auto;
      margin-bottom: 15px;
    }

    a {
      margin: auto;
    }
  }
`
