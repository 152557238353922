import { graphql, useStaticQuery } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import React from "react"
import Button from "../../../common/button"

import * as Styled from "./Empfehlung.styles"

const query = graphql`
  query {
    achtsamkeit: file(relativePath: { regex: "/buch.png$/" }) {
      childImageSharp {
        gatsbyImageData(
          layout: FULL_WIDTH
          placeholder: TRACED_SVG
          quality: 100
        )
      }
    }
  }
`

const Empfehlung = () => {
  const { achtsamkeit } = useStaticQuery(query)

  const image: any = getImage(achtsamkeit)
  return (
    <Styled.Container>
      <h3>Unsere Buchempfehlung</h3>
      <div className="img">
        <GatsbyImage alt="test" image={image} objectFit="cover" />
      </div>
      <div className="content">
        <p className="title">Zwischen Achtsamkeit und Pragmatismus</p>
        <p className="subtitle">
          Souverän agieren in herausfordernden Situationen
        </p>
        <p className="text">
          Dieses Buch zeigt, wie man auch unter Stress und Anspannung
          zielorientiert und konzentriert bleiben kann. Es ist geschrieben für
          Menschen, die ihre mentale Stärke und ihre Kommunikationskompetenzen
          verbessern möchten, und für Menschen im Job, die ihr Selbstmanagement
          und ihre Kommunikationsstrategien im beruflichen Alltag
          professionalisieren möchten. Mit einem Vorwort von Gunther Schmidt.
        </p>
        <Button
          link={false}
          href="https://link.springer.com/book/10.1007/978-3-662-58915-1#aboutBook"
          type="middle"
        >
          Hier bestellen
        </Button>
      </div>
    </Styled.Container>
  )
}

export default Empfehlung
