import { Link } from "gatsby"
import React from "react"

import * as Styled from "./Button.styles"

export type LinkType = "big" | "middle" | "small"
interface Props {
  type: LinkType
  link?: boolean
  href: string
}

const Button = ({
  type,
  link = true,
  href,
  children,
}: React.PropsWithChildren<Props>) => {
  return link ? (
    <Styled.SLink to={href || "/"} type={type}>
      {children}
    </Styled.SLink>
  ) : (
    <Styled.A
      href={href || "/"}
      target="_blank"
      rel="noopener noreferrer"
      type={type}
    >
      {children}
    </Styled.A>
  )
}

export default Button
