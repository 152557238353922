import React from "react"
import Section from "./components"
import { graphql, useStaticQuery } from "gatsby"

import * as Styled from "./Aktuelles.styles"
import { getImage } from "gatsby-plugin-image"

import agile_fuehrungPDF from "../../../../assets/pdfs/aktuelles_agile_fuehrung.pdf"

const query = graphql`
  query {
    agile_fuehrung: file(relativePath: { regex: "/agile_fuehrung.jpg$/" }) {
      childImageSharp {
        gatsbyImageData(
          layout: FULL_WIDTH
          placeholder: TRACED_SVG
          quality: 100
        )
      }
    }
    buch: file(relativePath: { regex: "/buch.png$/" }) {
      childImageSharp {
        gatsbyImageData(
          layout: FULL_WIDTH
          placeholder: TRACED_SVG
          quality: 100
        )
      }
    }
  }
`

const Aktuelles = () => {
  const { agile_fuehrung, buch } = useStaticQuery(query)

  const agile: any = getImage(agile_fuehrung)
  const buchImg: any = getImage(buch)

  return (
    <Styled.Container>
      <h3>Aktuelles</h3>
      <Section
        date="20.05.2024"
        imgAlt="test"
        title="Navigieren in agilen Zeiten"
        text="Selbstführung – Führung im Team – Führung in der Organisation. Agilität ist ein Führungsverständnis, das sowohl den Anforderungen der VUCA-Welt als auch dem demographischen (Werte-)Wandel gerecht wird. Agile Führung ist kein neuer Führungsstil, sondern eine Haltung. Sie spiegelt sich auf drei Ebenen wieder: 1. Selbstreflexion und Selbstregulation, 2. Teamdynamik, Konfliktlösung und Kommunikation und 3. Organisationsstrukturen und -kultur."
        linkText="zum Artikel"
        url={agile_fuehrungPDF}
        img={agile}
        link={false}
      />
      <Section
        date="11.04.2024"
        imgAlt="test"
        title="Seminar zum Buch Zwischen Achtsamkeit Und Pragmatismus"
        text="Zu unserem Buch zwischen Achtsamkeit und Pragmatismus bieten wir ein Seminar auf Anfrage an. Im Seminar diskutieren wir über die im Buch beschriebenen Themen und klären offene Fragen. Melden Sie sich bei uns für einen Termin!"
        linkText="Anfrage senden"
        url="mailto: info@ammerthaler-institut.de"
        img={buchImg}
      />
    </Styled.Container>
  )
}

export default Aktuelles
