import styled from "styled-components"
import breakpoints from "../../../../configs/breakpoints"

export const Container = styled.section`
  grid-column: 1 / -1;
  h3 {
    margin-bottom: var(--gap-50);
    margin-top: var(--gap-165);
  }

  @media screen and (max-width: ${breakpoints.tablet}) {
    h3 {
      margin-top: var(--gap-83);
    }
  }

  @media screen and (max-width: 429px) {
    h3 span {
      &:first-child:after {
        content: "-";
      }
    }
  }
`

export const LiText = styled.div`
  display: inline-block;
  padding: var(--gap-32);
  min-width: 350px;
  min-height: 290px;
  width: 100%;
`

export const Ul = styled.ul`
  display: flex;
  flex-wrap: wrap;

  & > :nth-child(odd) {
    margin-right: var(--gap-18);
  }

  li {
    display: flex;
    border: 1px solid var(--clr-green);
    width: calc(50% - var(--gap-18));
    max-width: 50%;
    align-items: center;
    margin-bottom: var(--gap-50);

    .img {
      width: 100%;
      min-width: 100px;
      max-width: 200px;
      margin: 13px;
      margin-right: 5%;
    }

    h3 {
      margin-top: 0;
    }

    h3,
    p {
      margin-bottom: var(--gap-27);
    }
  }

  @media screen and (max-width: 1250px) {
    flex-direction: column;

    li {
      width: 100%;
      max-width: 100%;
      margin-right: 0;
      margin-bottom: var(--gap-32);

      .img {
        width: 100%;
        max-width: 150px;
        margin-right: 0;
      }
    }
  }

  @media screen and (max-width: ${breakpoints.mobile}) {
    li {
      flex-direction: column;

      & div:nth-child(2) {
        padding: var(--gap-32);
        min-width: unset;
        min-height: unset;
        width: 100%;
      }
    }
  }
`
