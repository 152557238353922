import styled from "styled-components"
import breakpoints from "../../../../configs/breakpoints"

export const Intro = styled.p`
  margin: var(--gap-18) 0;
  margin-top: var(--gap-13);
  grid-column: 1/ -1;
  max-width: 750px;
`

export const ImgContainer = styled.div`
  grid-column: 2/9;
  margin-left: calc(var(--grid-gap-16) * -1);
`

export const Text = styled.div`
  grid-column: span 4;
  border: 1px solid var(--clr-green);
  padding: var(--gap-50);

  @media screen and (max-width: ${breakpoints.mobile}) {
    padding: var(--gap-18);
  }
`

export const Quote = styled.div`
  blockquote {
    font-size: 1.45em;
    font-weight: bold;
  }
  p {
    margin-bottom: var(--gap-50);
  }
`

export const Profil = styled.div`
  &:first-child {
    margin-bottom: var(--gap-50);
  }

  p:first-child {
    font-size: 1.45em;
    font-weight: bold;
    margin-bottom: var(--gap-18);
  }
`

export const ProfilContainer = styled.div`
  @media screen and (max-width: 1350px) {
    display: flex;

    ${Profil} {
      max-width: 47.5%;

      &:first-child {
        margin-right: 5%;
      }
    }
  }

  @media screen and (max-width: ${breakpoints.tablet}) {
    display: block;

    ${Profil} {
      max-width: 100%;

      &:first-child {
        margin-bottom: var(--gap-66);
      }
    }
  }

  @media screen and (max-width: 375px) {
    font-size: 1em;
  }
`

export const Container = styled.section`
  display: contents;

  h3 {
    margin-top: var(--gap-107);
  }

  h3,
  a {
    grid-column: 1/ -1;
  }

  a {
    justify-self: center;
    margin-top: var(--gap-13);
  }

  @media screen and (max-width: ${breakpoints.desktop}) {
    ${ImgContainer} {
      display: flex;
      justify-content: center;
      grid-column: 1/9;
      margin-left: 0;
    }

    ${Text} {
      grid-column: span 4;
    }
  }

  @media screen and (max-width: 1350px) {
    ${ImgContainer}, ${Text} {
      grid-column: 1/-1;
    }
  }

  @media screen and (max-width: ${breakpoints.desktop}) {
    ${Quote} {
      blockquote {
        font-size: 1.25em;
      }
    }

    ${Profil} {
      & p:first-child {
        font-size: 1.25em;
      }
    }
  }

  @media screen and (max-width: ${breakpoints.tablet}) {
    h3 {
      margin-top: var(--gap-83);
    }
  }
`
