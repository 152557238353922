import React from "react"
import { slugs } from "@configs/slugs"
import Button from "@modules/common/button"

import * as Styled from "./Intro.styles"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { graphql, useStaticQuery } from "gatsby"

const query = graphql`
  query {
    startseite: file(relativePath: { regex: "/startseite.jpg$/" }) {
      childImageSharp {
        gatsbyImageData(
          layout: FULL_WIDTH
          placeholder: TRACED_SVG
          quality: 100
        )
      }
    }
  }
`

const Intro = () => {
  const { startseite, startseite_s } = useStaticQuery(query)
  const image: any = getImage(startseite)
  const imageS: any = getImage(startseite_s)

  return (
    <Styled.Container>
      <Styled.Content>
        <h1>Herzlich Willkommen im Ammerthaler Institut!</h1>
        <p>
          Für Organisationen geht es darum, sich ständig auf neue
          Herausforderungen einzustellen. Wir unterstützen Sie dabei, auch in
          schwierigen und komplexen Situationen den Überblick zu bewahren und
          sich neu auszurichten.
        </p>
        <Button href={slugs["grundhaltung-und-werte"]} type="middle">
          Unsere Arbeitsweisen
        </Button>
      </Styled.Content>
      <div className="img">
        <GatsbyImage alt="test" image={image} />
      </div>
    </Styled.Container>
  )
}

export default Intro
