import { GatsbyImage, getImage, ImageDataLike } from "gatsby-plugin-image"
import React from "react"
import Button from "../../../../common/button"

import * as Styled from "./Section.styles"

interface Props {
  date?: string
  title: string
  text: string
  url: string
  linkText: string
  img: ImageDataLike
  imgAlt: string
  link?: boolean
}

const Section = ({
  date,
  title,
  text,
  url,
  linkText,
  img,
  imgAlt,
  link = true,
}: Props) => {
  const image: any = getImage(img)
  return (
    <Styled.Container>
      <Styled.Content>
        {date && <p className="date">{date}</p>}
        <h3>{title}</h3>
        <p>{text}</p>
        <Button link={link} href={url} type="middle">
          {linkText}
        </Button>
      </Styled.Content>
      <Styled.ImgWrapper>
        <GatsbyImage alt={imgAlt} image={image} objectFit="contain" />
      </Styled.ImgWrapper>
      <Button link href={url} type="middle">
        {linkText}
      </Button>
      <div className="margin"></div>
    </Styled.Container>
  )
}

export default Section
